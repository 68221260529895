const animation = () => {
  //wow
  const animations = new window.WOW().init();

  //titles
  const $titles = $(".js-title");

  if ($titles.length) {
    $(document).ready(function() {
      $titles.lettering();
    });

    $(document).ready(function() {
      animation(".js-main-title");
    }, 500);

    function animation(i) {
      if (i === ".js-main-title") {
        var id = i + " span";
      } else {
        var id ="#" + i + " span";
      }
      var title = new TimelineMax();
      title.staggerFromTo(id, 0.5,
      {ease: Back.easeOut.config(1.7), opacity: 0, bottom: 0},
      {ease: Back.easeOut.config(1.7), opacity: 1, bottom: 0}, 0.05
      );
    }

    //код будет работать везде, кроме ie благодаря условию ниже
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return;
    } else {

      function scrollTracking() {

        $(".js-side-title").each(function () {
          let hash = $(this).attr('id');
          let hashs = $("#" + hash);

          var wt = $(window).scrollTop();
          var wh = $(window).height();
          var et = hashs.offset().top;
          var eh = hashs.outerHeight();
          var dh = $(document).height();

          if (wt + wh >= et || wh + wt == dh || eh + et < wh) {

            if ($(this).hasClass("scrolling")) {
              return;
            } else {
              $(this).addClass("scrolling");

              var id = "#" + hash + " span";
              var title = new TimelineMax();
              title.staggerFromTo(id, 0.5,
              {ease: Back.easeOut.config(1.7), opacity: 0, bottom: 0},
              {ease: Back.easeOut.config(1.7), opacity: 1, bottom: 0}, 0.05
              );
            }

          }

        });
      }

      $(document).ready(function(){
        scrollTracking();
      });

      var scrollPos = 0;
      $(window).scroll(function() {
        var st = $(this).scrollTop();

        if (st > scrollPos) {


          scrollTracking();

        } else {
          $(".js-side-title").each(function() {
            if ($(this).hasClass("scrolling")) {
              $(this).removeClass("scrolling");
            }
          });
        }
        scrollPos = st;
      });

    }


  }
};

export default animation;
