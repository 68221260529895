const sliders = () => {
  const Swiper = window.Swiper;

  // Slider intro
  const intro = document.querySelector(".js-intro-slider");

  if (intro) {
    const mySwiper = new Swiper(".js-intro-slider.swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 800,
      pagination: {
        el: ".js-intro-slider .swiper-pagination",
        clickable: true,
      },
    });
  }


  // Slider partners
  const partners = document.querySelector(".js-partners-slider");

  if (partners) {
    const mySwiper = new Swiper(".js-partners-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 15,
      navigation: {
        nextEl: ".js-partners-slider .swiper-button-next",
        prevEl: ".js-partners-slider .swiper-button-prev",
      },
      breakpoints: {
        450: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        650: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        800: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        982: {
          slidesPerView: 5,
          spaceBetween: 40,
        },
        1100: {
          slidesPerView: 6,
          spaceBetween: 40,
        },
      },
    });
  }


  // Slider hits
  const hits = document.querySelector(".js-hits-slider");

  if (hits) {
    const mySwiper = new Swiper(".js-hits-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 15,
      navigation: {
        nextEl: ".js-hits-slider .swiper-button-next",
        prevEl: ".js-hits-slider .swiper-button-prev",
      },
      breakpoints: {
        510: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }
    });
  }


  // Slider categories
  const categories = document.querySelector(".js-category-slider");

  if (categories) {
    const mySwiper = new Swiper(".js-category-slider .swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      freeMode: true,
      freeModeSticky: true,
      freeModeMinimumVelocity: 0.01,
      freeModeMomentumRatio: 5,
      navigation: {
        nextEl: ".js-category-slider .swiper-button-next",
        prevEl: ".js-category-slider .swiper-button-prev",
      },
      breakpoints: {
        610: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        910: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }
    });
  }


  // Slider about
  const container = document.querySelector(".js-about-slider");

  if (container) {
    const mySwiper = new Swiper(".js-about-slider.swiper-container", {
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 800,
      pagination: {
        el: ".js-about-slider .swiper-pagination",
        clickable: true,
      },
    });
  }
};

export default sliders;
