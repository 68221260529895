const sort = () => {

  // Function for adding class
  function clickHandler(link, sel) {

    let selector = sel;

    if (link.hasClass(selector)) {
      link.removeClass(selector);
      link.blur();
    } else {
      link.addClass(selector);
      link.blur();
    }
  }

  // Sort goods for price
  const $sortLink = $(".js-sort");

  if ($sortLink) {

    $sortLink.on("click", function(evt) {
      evt.preventDefault();
      clickHandler($sortLink, "ascending");
    });
  }

  // All categories in filter
  const $categoriesLink =  $(".js-all-categories");

  if ($categoriesLink) {
    const $list = $(".js-categories-list");
    const $items = $(".js-categories-list li");

    $categoriesLink.on("click", function (evt) {
      evt.preventDefault();

      if ($categoriesLink.hasClass("show-all")) {
        $categoriesLink.removeClass("show-all");
        $list.removeClass("transform");
        $list.removeClass("show-all");
        $categoriesLink.blur();

      } else {
        $categoriesLink.addClass("show-all");

        //Добавляем через цикл каждому итему transition-delay больше, чем предыдущему
        let value = 0;
        for (let i = 0; i < $items.length; i++) {
          if (i > 4) {
            value = value + 100;
            let val = value + "ms";
            $($items[i]).css("transition-delay", val);
          }
        }

        $list.addClass("show-all");
        $categoriesLink.blur();

        //Реализуем плавное появление итемов
        $list.addClass("transform");


      }

    });
  }

  //Active state for catalog's categories
  const $linksContainer = $(".js-active-state");

  if ($linksContainer) {
    const links = $(".js-active-state a");

    links.each(function() {
      const $this = $(this);

      $this.on("click", function() {
        links.each(function() {
          $(this).removeClass("active");
        });

        if ($this.hasClass("active")) {
          $this.removeClass("active");
        } else {
          $this.addClass("active");
        }

      });
    });
  }

};

export default sort;
