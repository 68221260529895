const tel = () => {
  // Mask for tel
  const $formBlocks = $(".section-form");

  if (!$formBlocks.length) {
    return;
  }

  $formBlocks.each(function () {
    const $thiss =  $(this);
    const $input = $("#tel");

    if ($input) {
      $input.mask("+7 (999) 999-99-99");
    }

  });
};

export default tel;
