const stickyMenu = () => {
  const $stickyHeaderMenu = $(".js-sticky-menu");
  const $stickyHeaderMenuEl = $(".js-sticky-menu .js-container");
  const $stickyHeaderBtn = $(".js-sticky-menu-btn");

  const keyCode = {
    ESC: 27,
  };

  const open = () => {
    $stickyHeaderMenuEl.removeClass("show");
    $stickyHeaderMenu.slideDown(300);
    $stickyHeaderMenu.addClass("show");
    $stickyHeaderMenuEl.addClass("show");
    $stickyHeaderBtn.addClass("active");
  };

  const close = () => {
    $stickyHeaderMenu.slideUp(300);
    $stickyHeaderMenu.removeClass("show");
    $stickyHeaderBtn.removeClass("active");
    $stickyHeaderBtn.blur();
  };

  $stickyHeaderBtn.click(function() {
    if ( $stickyHeaderMenu.hasClass("show")) {
      close();
    } else {
      open();
    }
  });

  // Определение и установка координаты top для menu
  const resizeTopCoordinate = () => {
    let stickyHeight = $(".header-sticky").outerHeight();

    let a = stickyHeight + "px";

    $stickyHeaderMenu.css("top", a);
  };
  resizeTopCoordinate();

  $(window).resize(function() {
    resizeTopCoordinate();
  });

  // Закрытие header-menu при нажатии вне меню
  $(document).on('mouseup', function(evt) {
    if (evt.target.closest(".js-sticky-menu") === null && evt.target.closest(".js-sticky-menu-btn") === null) {
      close();
    }
  });

  // Закрытие header-menu по ESC
  $(document).on("keydown", function(evt) {
    if (evt.keyCode === keyCode.ESC && $stickyHeaderMenu.hasClass("show")) {
      close();
    }
  });
};

export default stickyMenu;
