const filter = () => {
  //Открытие фильтра в каталоге
  const $filterBtn = $(".js-filter-btn");
  if ($filterBtn) {

    const $filter = $(".js-filter");
    const $btnClose = $(".js-close-filter");
    const $overlay = $(".overlay");

    $filterBtn.on("click", function (evt) {
      evt.preventDefault();
      if ($filter.hasClass("open")) {
        $filter.removeClass("open");
        $overlay.fadeOut(300);
      } else {
        $filter.addClass("open");
        $overlay.fadeIn(300);
      }
    });

    $btnClose.on("click", function () {
      if ($filter.hasClass("open")) {
        $filter.removeClass("open");
        $overlay.fadeOut(300);
      }
    });

    $(document).on('mouseup', function(evt) {
      if (evt.target.closest(".js-filter") === null && evt.target.closest(".js-close-filter") === null) {
        if ($filter.hasClass("open")) {
          $filter.removeClass("open");
          $overlay.fadeOut(300);
        }
      }
    });
  }

  const $categoriesBtn = $(".js-categories-btn");

  if ($categoriesBtn) {

    const $categories = $(".js-categories");
    const $btnClose = $(".js-close-categories");
    const $overlay = $(".overlay");

    $categoriesBtn.on("click", function (evt) {
      evt.preventDefault();
      if ($categories.hasClass("open")) {
        $categories.removeClass("open");
        $overlay.fadeOut(300);
      } else {
        $categories.addClass("open");
        $overlay.fadeIn(300);
      }
    });

    $btnClose.on("click", function () {
      if ($categories.hasClass("open")) {
        $categories.removeClass("open");
        $overlay.fadeOut(300);
      }
    });

    $(document).on('mouseup', function(evt) {
      if (evt.target.closest(".js-categories") === null && evt.target.closest(".js-close-categories") === null) {
        if ($categories.hasClass("open")) {
          $categories.removeClass("open");
          $overlay.fadeOut(300);
        }
      }
    });
  }

};

export default filter;
