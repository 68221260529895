const headerScroll = () => {
  const main = document.querySelector("main");

  const $header = $(".header");

  if ($header) {
    const $headerSticky = $(".header-sticky");

    // Header становится fixed, main добавляется отступ
    const scrollHeader = () => {
      const introTop = main.getBoundingClientRect().top;

      if (introTop === -50 || introTop < -50) {
        $headerSticky.addClass("transform");
      } else {
        $headerSticky.removeClass("transform");
      }

      const isVisible = introTop < -100;

      if (isVisible) {
        $headerSticky.addClass("is-scroll");
      } else if ($headerSticky.hasClass("is-scroll") && !isVisible) {
        $headerSticky.removeClass("is-scroll");
      }
    };

    $(window).on("scroll", scrollHeader);


    // Открытие и закрытие header-menu с помощью fade
    const $headerMenu = $(".js-desk-menu");
    const $headerMenuEl = $(".js-desk-menu .js-container");
    const $btn = $(".js-desk-menu-btn");

    const keyCode = {
      ESC: 27,
    };

    const open = () => {
      $headerMenuEl.removeClass("show");
      $headerMenu.slideDown(300);
      $headerMenu.addClass("show");
      $headerMenuEl.addClass("show");
      $btn.addClass("active");
    };

    const close = () => {
      $headerMenu.slideUp(300);
      $headerMenu.removeClass("show");
      $btn.removeClass("active");
      $btn.blur();
    };

    $btn.click(function() {
      if ($headerMenu.hasClass("show")) {
        close();
      } else {
        open();
      }
    });

    // Определение и установка координаты top для header-menu
    const resizeTopCoordinate = () => {
      let height = $(".header").outerHeight();
      let a = height + "px";

      $headerMenu.css("top", a);
    };
    resizeTopCoordinate();

    $(window).resize(function() {
      resizeTopCoordinate();
    });

    // Закрытие header-menu при нажатии вне меню
    $(document).on('mouseup', function(evt) {
      if (evt.target.closest(".js-desk-menu") === null && evt.target.closest(".js-desk-menu-btn") === null) {
        close();
      }
    });

    // Закрытие header-menu по ESC
    $(document).on("keydown", function(evt) {
      if (evt.keyCode === keyCode.ESC && $headerMenu.hasClass("show")) {
        close();
      }
    });
  }

};

export default headerScroll;
