const menuOpen = () => {
  // Открытие моб меню
  const $buttonsMenu = $(".header__menu-btn");

  if ($buttonsMenu.length) {
    const $menu = $(".menu-mob");
    const $buttonClose = $(".js-btn-close");

    $buttonsMenu.each(function () {
      const $btn = $(this);
      $btn.click(function() {

        if ($menu.hasClass("is-show")) {

          const pos = parseInt($("body").attr("data-scroll"), 10);
          $menu.removeClass("is-show");
          $btn.removeClass("is-show");

          $("body").removeClass("is-menu-open").removeAttr("data-scroll");
          window.scrollTo(0, pos);

        } else {

          $menu.addClass("is-show");

          setTimeout(function () {
            $btn.addClass("is-show");
          }, 300);

          setTimeout(function () {
            const pagePos = $(window).scrollTop();
            $("body").addClass("is-menu-open").attr("data-scroll", pagePos);
          }, 800);

        }
      });
    });

    $buttonClose.click(function () {
      const pos = parseInt($("body").attr("data-scroll"), 10);
      $menu.removeClass("is-show");
      $buttonsMenu.each(function () {
        const $btn = $(this);
        $btn.removeClass("is-show");
      });

      $("body").removeClass("is-menu-open").removeAttr("data-scroll");
      window.scrollTo(0, pos);
    });

    const currentHeaderState = () => {
      if ($(".header-sticky").hasClass("is-scroll")) {
        $buttonsMenu.each(function () {
          const $btn = $(this);
          $btn.css("z-index", "1");
        });

        $buttonClose.addClass("show");

      } else if ($buttonClose.hasClass("show")) {
        $buttonClose.removeClass("show");

        $buttonsMenu.each(function () {
          const $btn = $(this);
          $btn.css("z-index", "35");
        });
      }
    };
    currentHeaderState();

    $(window).on("scroll", function() {
      currentHeaderState();
    });

  }

};

export default menuOpen;
